import { useState } from 'react';

import { ModalWrapper } from '../../../ConfirmCancelModalContext/ModalWrapper';
import {
  OrganizerMultiSelect,
  type OrganizerSelectOption,
} from '../../../Organization/OrganizerSelect';
import { useUser } from '../../../UserContext';

interface CreateGroupModalProps {
  onClose: () => void;
  onConfirm: (params: { name: string; emails: string[] }) => void;
}

export function CreateGroupModal({
  onClose,
  onConfirm,
}: CreateGroupModalProps) {
  const [groupName, setGroupName] = useState('');
  const [emailOptions, setEmailOptions] = useState<OrganizerSelectOption[]>([]);
  const user = useUser();
  const orgId = user.organizer?.orgId;

  const handleCreate = () => {
    const trimmedName = groupName.trim();
    if (!trimmedName) return;

    const emails = emailOptions.map((opt) => {
      if (opt.kind === 'organizer') {
        return opt.organizer.email;
      } else {
        return opt.value;
      }
    });

    onConfirm({ name: trimmedName, emails });
  };

  const isDisabled = !groupName.trim();

  return (
    <ModalWrapper
      borderStyle='gray'
      containerClassName='w-[600px] h-auto'
      innerClassName='bg-main-layer flex flex-col'
      onClose={onClose}
    >
      <div className='px-8 pt-8 pb-8 text-white flex flex-col gap-6'>
        {/* Header */}
        <div className='text-2xl font-bold text-center'>Create New Group</div>

        {/* Group Name Input */}
        <div className='flex flex-col gap-2'>
          <label className='text-sms font-medium text-icon-gray'>
            Group Name <span className='text-red-500'>*</span>
          </label>
          <input
            type='text'
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
            placeholder='Enter group name'
            className='field'
          />
        </div>

        {/* Optional Email Input */}
        <div className='flex flex-col gap-2'>
          <label className='text-sms font-medium text-icon-gray'>
            Add Members (Optional)
          </label>
          <OrganizerMultiSelect
            orgId={orgId ?? ''}
            options={emailOptions}
            onChange={setEmailOptions}
            creatable
            placeholder='Enter one or more email addresses'
            className='w-full'
            scrollable={{ maxHeight: 200 }}
          />
        </div>

        {/* Help Text */}
        <p className='text-sms text-icon-gray text-center'>
          You can always add more members to the group later.
        </p>

        {/* Action Buttons */}
        <div className='flex justify-center gap-4 mt-4'>
          <button
            type='button'
            onClick={onClose}
            className='btn btn-secondary px-6 py-2'
          >
            Cancel
          </button>
          <button
            type='button'
            onClick={handleCreate}
            disabled={isDisabled}
            className={`btn btn-luna-primary px-6 py-2 ${
              isDisabled ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            Create Group
          </button>
        </div>
      </div>
    </ModalWrapper>
  );
}
